import {
    Minimize2 as Minimize2Icon,
    ChevronDown as ChevronDownIcon,
    ChevronUp as ChevronUpIcon,
} from 'feather-icons-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import splitbee from '@splitbee/web'
import { SplitbeeEvents } from '../helper'

export function Counter({ incrementCount, localCounter, onClearCounter }) {
    const [expanded, setExpanded] = useState(false)
    const [height, setHeight] = useState(300)
    const { t } = useTranslation()

    const onClick = (e, count = 1) => {
        let confirm = true;
        if (count > 1) {
            confirm = window.confirm(`${count} adet eklemek istediğinizden emin misiniz?\n\n🙏 Eklemiş olduğunuz sayının okunduğundan lütfen emin olunuz.\nNot: Bu işlem geri alınamaz`)
        }

        if (confirm) {
            incrementCount(e, count)
            window.parent.postMessage(JSON.stringify({
                action: "HAPTIC_FEEDBACK",
            }), '*')
            if (!expanded) {
                setExpanded(true)
            }
        }
    }

    const counterText = localCounter || '0'

    return (
        <>
            <div
                className={`fixed z-20 flex items-center w-full left-0 ${
                    expanded ? 'bottom-0' : 'bottom-10'
                }`}
            >
                {expanded && (
                    <>
                        <span className="w-full flex items-center absolute top-8 left-0 justify-between">
                            <button
                                className="p-4 text-gray-700 dark:text-white"
                                onClick={() => {
                                    if (height >= 300) {
                                        setHeight((height) => height - 100)
                                    }
                                    splitbee.track(
                                        SplitbeeEvents.counterHeight,
                                        { type: 'down', height }
                                    )
                                }}
                            >
                                <ChevronDownIcon />
                            </button>


                            <button
                                className='bg-neutral-50 dark:bg-neutral-700 dark:text-white py-1.5 px-3 rounded-full text-sm font-semibold'
                                onClick={e => onClick(e, 10)}
                            >
                                +10
                            </button>
                            <button
                                className='bg-neutral-50 dark:bg-neutral-700 dark:text-white py-1.5 px-3 rounded-full text-sm font-semibold'
                                onClick={e => onClick(e, 100)}
                            >
                                +100
                            </button>
                            <button
                                className='bg-neutral-50 dark:bg-neutral-700 dark:text-white py-1.5 px-3 rounded-full text-sm font-semibold'
                                onClick={onClearCounter}
                            >
                                Sayacı Sıfırla
                            </button>


                            <button
                                className="p-4 text-gray-700 dark:text-white"
                                onClick={() => {
                                    if (height <= 600) {
                                        setHeight((height) => height + 100)
                                    }
                                    splitbee.track(
                                        SplitbeeEvents.counterHeight,
                                        { type: 'up', height }
                                    )
                                }}
                            >
                                <ChevronUpIcon />
                            </button>
                        </span>

                        <span
                            className="absolute bottom-0 left-0 w-full text-center text-gray-500 py-2 flex items-center justify-center cursor-pointer dark:text-gray-300"
                            onClick={() => {
                                setExpanded(false)
                                splitbee.track(SplitbeeEvents.counterHeight, {
                                    type: 'minimize',
                                })
                            }}
                        >
                            <Minimize2Icon size={20} className={'mr-3'} />
                            {t('infoCounter')}
                        </span>
                    </>
                )}
                <span
                    onClick={(e) => onClick(e, 1)}
                    className={`cursor-pointer select-none transition-all dark:bg-neutral-800 bg-white shadow-2xl mx-auto flex items-center justify-center text-3xl dark:text-white text-herkul-700 mt-8
                    ${
                        expanded
                            ? 'w-full rounded-t-xl dark:text-white text-6xl'
                            : 'w-[150px] rounded-full'
                    }`}
                    style={{
                        height: expanded ? `${height}px` : 150,
                    }}
                >
                    {counterText}
                </span>
            </div>

            {expanded && <div className="h-[40vh]" />}
        </>
    )
}
